@import url(https://use.typekit.net/yjs2lsy.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  padding: 0;
  margin: 0;
  background: #a0e1fe;
  font-family: din-2014,sans-serif;
  font-weight: 800;
  font-style: normal;
}


* {
  box-sizing: border-box;
}


.fullSlot {
  z-index: 100;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: calc(100% - 60px);
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
  background-color: #1f2b5e;
}

.casinoName {
  font-family: fino-sans,sans-serif;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  font-size: 2em;
  color: rgba(255, 255, 255, 0.781);
  width: 100%;
  padding: 10px 0 0 0;
}

.price {
  font-family: hydrophilia-iced,sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  background-color: rgb(10, 22, 39);
  padding: 10px 0 10px 0;
  margin: 10px 0 10px 0;
  text-align: center;
  border: solid 2px #ffd900;
  width: 97%;
  height: 50px;
  overflow: hidden;
  font-weight: 500;
  font-size: 1.2em;
  border-radius: 5px;
}

.priceInd {
  margin: 0;
  animation: premio .5s forwards linear;
}

.renewButton {
  margin: 20px 0 0 0;
  padding: 20px;
  line-height: 0;
}

@keyframes premio {
  0% {transform: translateY(100px);}
  80% {transform: translateY(-5px);}
  100% {transform: translateY(0);}
}

.slot {
 display: flex;
    flex-direction: row;
    width: 100%;
    height: 316px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.slotFoot {
  display: flex;
  /* width: 60%; */
  align-items: center;
  justify-content: center;  
  padding: 10px;
  /* background-color: #dc9105;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-height: 150px; */
}

.betInput {
  font-family: hydrophilia-iced,sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  background-size: 20px;
  border: none;
  border-radius: 2px;
  font-size: 1.2em;
  margin: 20px;
  height: 40px;
  width: 100%;
  margin: 0 5px 0 0;
  flex: 1 1 auto;
  text-align: center;
}

.spinButton {
      color: #fff;
    border: 6px solid #dbb433;
    font-size: 2.4rem;
    background: #f05151;
    border-radius: 5rem;
    width: 120px;
    height: 120px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;
    padding: 0.5rem;
      box-shadow: 0 9px #999;
}
.spinButton:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.buyMoreButton {
  cursor: pointer;
  font-family: hydrophilia-iced,sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  font-size: 1em;
  width: calc(100% - 20px);
  height: 30px;
  margin-bottom: 10px;
  background-color: #ffd900;
  border: none;
  border-radius: 2px;; 
}

.row {
  flex: 1 0 auto;
  margin: 0 5px 0 5px;
  overflow: hidden;
  background-color: #3c058c;
  min-width: 250px;
  max-width: 250px;
  border-radius: 0.2rem;
  /* border: solid 2px #ffd9009c;
  background: linear-gradient(0deg,  rgb(204, 203, 203)  0%, rgb(255, 255, 255)20%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 80%, rgb(204, 203, 203) 100%); */
}

.ringMoving {
  height: 200px;
  line-height: 200px;
  text-align: center;
  font-size: 4em;
  animation: goaround 0.2s linear infinite;
}

.ringEnd {
      height: 240px;
    line-height: 150px;
    text-align: center;
    font-size: 4em;
    animation: stop .5s ease-out forwards;
}
.ringMoving_1 {
  height: 200px;
  line-height: 200px;
  text-align: center;
  font-size: 8em;
  animation: goaround 0.2s linear infinite;
}

.ringEnd_1 {
      height: 240px;
    line-height: 150px;
    text-align: center;
    font-size: 8em;
    animation: stop .5s ease-out forwards;
}

@keyframes goaround {
0% {transform: translateY(0);}
100% {transform: translateY(-1000px);}
}

@keyframes stop {
  0% {transform: translateY(0)}
  50% {transform: translateY(-90px)}
  75% {transform: translateY(-80px)}
  100% {transform: translateY(-85px)}
  }


  .header{
   background-color: #e7184e;
   min-height: 50px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 1rem 2rem;
  }
  .centerMenu{
        background-color: #fee954;
    min-width: 243px;
    padding: 0.5rem 5rem;
    text-align: center;
    font-size: 2.5rem;
    box-shadow: 0px -5px 1px 8px #91c3be;
    color: #3c058c;
  }
  .outputWrp{
    height: 39rem;
        background-color: #fee954;
    padding: 1rem 5rem;
    display: flex;
    align-items: end;
        justify-content: center;
    
  }
  .imgWrp{
    width: 200px;
    height: 300px; 
  }
  .imgWrp > img{
    width: 100%;
    height: 100%;
  }
  .resultWrp{
      background-color: #3c058c;
    height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .plus{
        font-size: 7rem;
    font-weight: 200;
    color: #d2891a;
  }
  .accept{
    
    border: 5px solid #d2891a;
    background: #37006d;
    color: #d2891a;
    font-weight: 700;
    padding: 0.5rem 2rem;
    font-size: 1.3rem;
    border-radius: 0.2rem;
    cursor: pointer;
    margin-right: 0.5rem;
  }
  .buttonWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
